import { Operation } from '../components/operations'
import { apiCall } from '../utils/api'

export type GetOperationListRequest = {
   query: string
   page: number
   size: number
   sort: string[]
   loyaltyPlanIds: number[]
   from?: string
   to?: string
}
export type GetOperationExportListRequest = {
   query: string
   page: number
   size: number
   sort: string[]
   loyaltyPlanIds: number[]
   from?: string
   to?: string
}
export type requestCreateOperation = {
   account: string
   loyaltyPlanId: number
   levelId: number
   operationType: string
   hotelId: string
   hotelName: string
   serviceType: string
   memberId: string
   reservationUuid?: string
   reservationName: string
   reservationCode: string
   reservationDate: string
   reservationSubTotal: number
   reservationTotal: number
   currency: string
}
export type getOperationListResponse = {
   items: Array<Operation>
   totalSize: number
}
interface operationImportRequest {
   loyaltyPlanId: number
   file: FormData | File
}
export interface responseImported {
   account: string
   loyaltyPlanId: number
   path: string
   statusId: number
}
export async function getOperationExportList(request: GetOperationExportListRequest): Promise<any> {
   const { query, page, size, loyaltyPlanIds, from, to } = request
   let params = {}
   if (query) params = { ...params, query }
   if (page >= 0) params = { ...params, page }
   if (size) params = { ...params, size }
   if (loyaltyPlanIds.length) params = { ...params, loyaltyPlanIds: loyaltyPlanIds.join(',') }
   if (from) params = { ...params, from: from }
   if (to) params = { ...params, to: to }
   let extraHeaders = {
      Accept: '*/* , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
   }
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/export`,
      method: 'GET',
      params,
      headers: extraHeaders,
      responseType: 'arraybuffer',
   })
   return result.data
}
export async function getOperationList(request: GetOperationListRequest): Promise<getOperationListResponse> {
   const { query, page, size, loyaltyPlanIds, from, to } = request
   let params = {}
   if (query) params = { ...params, query }
   if (page >= 0) params = { ...params, page }
   if (size) params = { ...params, size }
   if (loyaltyPlanIds.length) params = { ...params, loyaltyPlanIds: loyaltyPlanIds.join(',') }
   if (from) params = { ...params, from: from }
   if (to) params = { ...params, to: to }

   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations`,
      method: 'GET',
      params,
   })
   return { items: result.data, totalSize: parseInt(result.headers['x-total-count']) }
}
export async function createOperation(request: requestCreateOperation): Promise<Operation> {
   const { ...data } = request
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations`,
      method: 'POST',
      data: data,
   })
   return result.data
}
export async function importFileOperations(request: operationImportRequest): Promise<responseImported> {
   const { loyaltyPlanId, file } = request
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/loyaltyPlan/${loyaltyPlanId}/upload`,
      method: 'POST',
      data: { file: file },
      headers: {
         Accept: 'application/json, text/plain, */*',
         'Content-Type': 'multipart/form-data;',
      },
   })

   return result.data
}
