// eslint-disable-next-line
export default {
   'dashboard.accumulatedPoints': 'Puntos acumulados',
   'dashboard.redeemedPoints': 'Puntos redimidos',
   'dashboard.activations': 'Activaciones',
   'dashboard.periodActivations': 'Activaciones en el periodo',
   'dashboard.apliedAndRedeemedPoints': 'Puntos aplicados y redimidos',
   'dashboard.activationsPerChannel': 'Activaciones por canal',
   'dashboard.membershipsPerLevel': 'Membresías por nivel',
   'dashboard.redemptions': 'Redenciones',
   'dashboard.applications': 'Aplicaciones',
   'dashboard.dateFormat': 'LLL. dd',
}
