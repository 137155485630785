import { useMemo } from 'react'
import qs from 'qs'
import Title from '../common/Title'
import { useIntl } from 'react-intl'
import Card from '../common/Card'
import { Button, Table } from 'ui'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Partner } from '../partners'
import { getCurrentValues } from '../../utils/operationUtils'
import { createDateFromISO } from 'utils/dates'
import Filters, { OperationFilterForm } from './components/Filters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faFileCirclePlus, faShareFromSquare } from '@fortawesome/free-solid-svg-icons'
import { getOperationExportList } from '../../api/operation'
import useGetOperationList from '../../hooks/useGetOperationList/useGetOperationList'
import { useMutation } from '@tanstack/react-query'

import { useAppSelector } from '../../store/hooks'

export type Operation = {
   id: number
   reservationName: string
   reservationCode: string
   reservationTotal: number
   reservationDate: string
   currency: string
   points: number
   pointsAccumulated: number | null
   pointsRedeemed: number | null
   member: Partner
   createdAt: string
}

type Props = {}

export const Operations = (props: Props) => {
   const intl = useIntl()
   const { locale } = useAppSelector((state) => state.base)
   const navigate = useNavigate()
   const location = useLocation()
   const { accountCode } = useParams<{ accountCode: string }>()

   const { data: operationList, status } = useGetOperationList(getCurrentValues(location.search))
   const { mutate } = useMutation({
      mutationFn: getOperationExportList,
   })

   const currentValues = getCurrentValues(location.search)

   const columns = useMemo(
      () => [
         {
            header: intl.formatMessage({ id: 'operations.reservation' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               return (
                  <div>
                     {row.original.reservationName}
                     <div className="text-sm text-neutral-400">{row.original.reservationCode}</div>
                  </div>
               )
            },
         },

         {
            header: intl.formatMessage({ id: 'operations.reservation-date' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               const isoDate = createDateFromISO(row.original.reservationDate).setLocale(locale)
               return <div>{isoDate.toFormat(intl.formatMessage({ id: 'date.format' }))}</div>
            },
         },
         {
            header: intl.formatMessage({ id: 'operations.partner' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               let fullName = `${row.original.member.firstName} ${row.original.member.lastName}`
               return (
                  <Link to={`/${accountCode}/partners/${row.original.member.id}`}>
                     {fullName}
                     <div className="text-sm text-neutral-400">{row.original.member.email}</div>
                  </Link>
               )
            },
         },
         {
            header: () => (
               <div className="text-right">{intl.formatMessage({ id: 'operations.accumulatedPoints' })}</div>
            ),
            accessorKey: 'pointsAccumulatedRemaining',
            cell: ({ row }: { row: { original: Operation } }) => (
               <div className="text-right">{row.original.pointsAccumulated}</div>
            ),
         },
         {
            header: () => <div className="text-right">{intl.formatMessage({ id: 'operations.redeemedPoints' })}</div>,
            accessorKey: 'pointsRedeemed',
            cell: ({ row }: { row: { original: Operation } }) => (
               <div className="text-right">{row.original.pointsRedeemed}</div>
            ),
         },
         {
            header: intl.formatMessage({ id: 'operations.creationDate' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               const isoDate = createDateFromISO(row.original.createdAt).setLocale(locale)
               return (
                  <div>
                     {isoDate.toFormat(intl.formatMessage({ id: 'date.format' }))}
                     <div className="text-sm text-neutral-400">{`${isoDate.toFormat('HH:mm')} hrs`}</div>
                  </div>
               )
            },
         },
      ],
      [intl, locale, accountCode],
   )

   const onFilter = (data: OperationFilterForm) => {
      const request = Object.assign({}, data)
      // request.from = undefined
      // request.to = undefined
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      ) //TODO: revisar si se soluciona el bug que encodea la coma https://github.com/ljharb/qs/issues/410
   }

   const onPageChange = (page: { page: number; size: number }) => {
      const request = Object.assign({}, currentValues, { ...page })
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      )
   }
   const onExport = () => {
      mutate(getCurrentValues(location.search), {
         onSuccess: (response) => {
            let excellName = `operations.xlsx`
            const blob = new Blob([response], {
               type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = excellName
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
         },
      })
   }
   const onClickCreate = () => {
      navigate(`create`)
   }
   const onImport = () => {
      navigate('import')
   }
   return (
      <>
         <Title
            className="flex justify-between gap-x-2  items-center "
            value={intl.formatMessage({ id: 'titles.operations' })}
         >
            <Button onClick={onClickCreate} className={'flex gap-x-2 items-center text-[12px] mr-4 '}>
               <FontAwesomeIcon icon={faFileCirclePlus} className={'text-gray-500 text '} />
               {intl.formatMessage({ id: 'operations.create' })}
            </Button>
         </Title>
         <div className="flex flex-col gap-y-4 mx-4">
            <Card className="py-4">
               <Filters className="px-4" onSubmit={onFilter} defaultValues={currentValues} />
               <Table
                  fetching={status === 'pending'}
                  columns={columns}
                  data={operationList?.items ?? []}
                  tableClassName="border-y"
                  footerClassName="px-4"
                  // onExport={onExport}
                  // exportText={intl.formatMessage({ id: 'operations.export' })}
                  showTableResume
                  pagination={{
                     defaultPageSize: currentValues.size,
                     defaultPageIndex: currentValues.page,
                     totalSize: operationList?.totalSize ?? 0,
                     onPageChange: onPageChange,
                  }}
               >
                  <div className="flex justify-between gap-x-2">
                     <Button onClick={onImport} className={'flex gap-x-2 items-center text-[12px]'}>
                        <FontAwesomeIcon icon={faDownload} className={'text-gray-500 text '} />
                        {intl.formatMessage({ id: 'operations.import' })}
                     </Button>
                     <Button onClick={onExport} className={'flex gap-x-2 items-center text-[12px]'}>
                        <FontAwesomeIcon icon={faShareFromSquare} className={'text-gray-500 text '} />
                        {intl.formatMessage({ id: 'operations.export' })}
                     </Button>
                  </div>
               </Table>
            </Card>
         </div>
      </>
   )
}
