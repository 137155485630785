import { Profile } from '../components/user/Login'
import { apiCall } from '../utils/api'

export async function getProfile(): Promise<Profile | null> {
   try {
      const result = await apiCall({
         url: `${process.env.REACT_APP_PUBLIC_CORE_API}/users/current`,
         method: 'GET',
      })

      return result.data
   } catch (err) {
      return null
   }
}
