import { Summary } from '../components/dashboard'
import { apiCall } from '../utils/api'
export type GetSummaryRequest = {
   loyaltyProgramId: number
   from?: string
   to?: string
}

export async function getSummary(request: GetSummaryRequest): Promise<Summary | null> {
   const { loyaltyProgramId } = request
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/plans/${loyaltyProgramId}/summary`,
      method: 'GET',
      params: {
         from: request.from,
         to: request.to,
      },
   })
   if (result.status !== 200) {
      throw new Error(`error ${result.status}`)
   }
   return result.data
}
