import React from 'react'
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button, Dropdown } from 'ui'
import { Input } from 'ui/fields'
import MultiCheck from 'ui/MultiCheck/MultiCheck'
import DateTypeSelector from '../../common/DateTypeSelector'
import { useGetProgramFilter } from '../../../hooks/useGetProgramFilter'

export type OperationFilterForm = {
   query: string
   dates?: {
      startDate: string
      endDate: string
      dateType: string
   }
   loyaltyPlanIds: Array<number>
}

type Props = {
   className?: string
   onSubmit: (data: {
      query: string
      dates?: {
         startDate: string
         endDate: string
         dateType: string
      }
      loyaltyPlanIds: Array<number>
   }) => void
   defaultValues?: {
      query?: string
      from?: string
      to?: string
      loyaltyPlanIds: Array<number>
   }
}

const Filters = ({ className, onSubmit, defaultValues }: Props) => {
   const { handleSubmit, register, watch, control, resetField } = useForm<OperationFilterForm>({
      defaultValues: {
         query: defaultValues?.query || '',
         loyaltyPlanIds: defaultValues?.loyaltyPlanIds || [],
         dates: {
            startDate: defaultValues?.from,
            endDate: defaultValues?.to,
            dateType: defaultValues?.from && defaultValues?.to ? 'other' : 'any',
         },
      },
      mode: 'onTouched',
   })
   const intl = useIntl()
   const formButton = React.useRef<HTMLFormElement>(null)
   const { data: programs, status: programsStatus } = useGetProgramFilter()

   const onSubmitEvent = (data: OperationFilterForm) => {
      const { query, ...rest } = data
      const result = { ...rest } as OperationFilterForm
      if (query && query.trim() !== '') {
         result.query = query.trim()
      }
      if (result.dates?.dateType === 'any') {
         result.dates = undefined
      }
      onSubmit(result)
   }

   const drawCount = (sizeSelected: number, total: number, fetching: boolean, word = 'Todas') => {
      if (fetching) {
         return '...'
      }
      if (sizeSelected > 0) {
         return `${sizeSelected} de ${total}`
      }
      return word
   }

   const drawDates = () => {
      const dates = watch('dates')
      if (dates && dates.dateType === 'other' && dates.startDate && dates.endDate) {
         return (
            <>
               {intl.formatMessage({ id: 'operations.dates' })}: {`${dates.startDate} - ${dates.endDate}`}
            </>
         )
      }

      return (
         <>
            {intl.formatMessage({ id: 'operations.dates' })}: {intl.formatMessage({ id: 'operations.any' })}
         </>
      )
   }

   return (
      <div className={classNames(className, 'mb-6')}>
         <form onSubmit={handleSubmit(onSubmitEvent)} ref={formButton}>
            <div className="flex flex-col-reverse md:flex-row gap-y-2 justify-between md:items-center">
               <div className="flex flex-row flex-wrap gap-y-2 gap-x-2">
                  <Dropdown onClose={handleSubmit(onSubmitEvent)}>
                     <Dropdown.Toggle className="cursor-pointer flex w-full items-center justify-between rounded-md bg-white px-3 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {intl.formatMessage({ id: 'partners.program' })}:{' '}
                        {drawCount(
                           watch('loyaltyPlanIds')?.length,
                           programs?.items?.filter((i) => i.hasPointBenefit)?.length ?? 0,
                           programsStatus === 'pending',
                           'Todos',
                        )}
                     </Dropdown.Toggle>
                     <Dropdown.Menu>
                        <ul>
                           <Controller
                              control={control}
                              name="loyaltyPlanIds"
                              render={({ field: { ref, ...rest } }) => (
                                 <MultiCheck {...rest}>
                                    {programs?.items?.map((option) => {
                                       if (!option.hasPointBenefit) {
                                          return null
                                       }
                                       return (
                                          <li key={option.id} className="hover:bg-gray-100">
                                             <MultiCheck.Check value={option.id} label={option.name} />
                                          </li>
                                       )
                                    })}
                                 </MultiCheck>
                              )}
                           />
                        </ul>
                     </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown onClose={handleSubmit(onSubmitEvent)}>
                     <Dropdown.Toggle className="cursor-pointer flex w-full items-center justify-between rounded-md bg-white px-3 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {drawDates()}
                     </Dropdown.Toggle>
                     <Dropdown.Menu>
                        <ul>
                           <Controller
                              control={control}
                              name="dates"
                              render={({ field: { ref, ...rest } }) => (
                                 <DateTypeSelector
                                    {...rest}
                                    placeholder={intl.formatMessage({ id: 'operations.selectDate' })}
                                 >
                                    <li>
                                       <DateTypeSelector.Range
                                          type="any"
                                          title={intl.formatMessage({ id: 'operations.any' })}
                                       />
                                    </li>
                                    <li>
                                       <DateTypeSelector.Range
                                          type="other"
                                          title={intl.formatMessage({ id: 'operations.other' })}
                                       />
                                    </li>
                                 </DateTypeSelector>
                              )}
                           />
                        </ul>
                     </Dropdown.Menu>
                  </Dropdown>
               </div>

               <div className="flex items-center relative">
                  {watch('query') && (
                     <FontAwesomeIcon
                        icon={faX}
                        className="absolute right-14 cursor-pointer"
                        onClick={() => {
                           resetField('query', { defaultValue: '' })
                           formButton.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
                        }}
                     />
                  )}
                  <Input
                     {...register('query')}
                     className="!rounded-r-none"
                     containerClassName="!m-0"
                     placeholder="Buscar por ID o socio"
                  />
                  <Button bsStyle="gray" type="submit" className="flex items-center !border-l-0 !rounded-l-none">
                     <i className="invisible inline-block max-w-0">.</i>
                     <FontAwesomeIcon icon={faSearch} />
                  </Button>
               </div>
            </div>
         </form>
      </div>
   )
}

export default Filters
