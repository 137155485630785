import { Country } from '../../../api/countries'
import { useWatch } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import { getCountryPhoneCode, getPhonesOptions } from '../../../utils/partnerUtils'
import './phoneSelector.css'

type Props = {
   countries: Array<Country>
   control: any
   register: any
}
export type UserInfoFormValuesInternal = {
   name?: string
   lastName?: string
   email?: string
   code?: string
   telephone?: string
}
const PhoneCodeSelector = ({ countries, control, register }: Props) => {
   const code = useWatch({
      control: control,
      name: 'code',
   })

   const getField = () => {
      if (!countries) {
         return null
      }

      const codes = getPhonesOptions(countries) as Array<any>
      const display = getCountryPhoneCode(countries, code)
      return (
         <span className="w-[85px] inline-block">
            <span className="absolute left-[15px] top-[5px] text-left flex items-center">
               <div className="w-[75px] inline-block overflow-hidden whitespace-nowrap text-ellipsis">
                  {display || '--'}
               </div>
               <FontAwesomeIcon icon={faSort} />
            </span>
            <select {...register('code')} className="itm-phone-select form-control">
               {codes.map((countrie, index) => (
                  <option key={`${countrie.code}-${index}`} value={countrie.code} className="text-black">
                     {countrie.value}
                  </option>
               ))}
            </select>
         </span>
      )
   }
   return getField()
}

export default PhoneCodeSelector
