import { useState, useCallback } from 'react'

export function useFileUpload(submitForm: Function, initialProgramId: number = 0) {
   const [showStatusBanner, setShowStatusBanner] = useState(false)
   const [programId, setProgramId] = useState(initialProgramId)
   const [file, setFile] = useState(null)

   const handleFileChange = useCallback(
      (file: File) => {
         setShowStatusBanner(true)
         if (file) {
            const formData = new FormData()
            formData.append('file', file)

            submitForm(
               {
                  loyaltyPlanId: programId,
                  file: file,
               },
               {
                  onSuccess: () => {
                     setShowStatusBanner(false)
                     setProgramId(initialProgramId)
                     setFile(null)
                  },
                  onError: () => {
                     setShowStatusBanner(false)
                  },
               },
            )
         }
      },
      [programId, submitForm, initialProgramId],
   )

   return {
      showStatusBanner,
      programId,
      setProgramId,
      file,
      setFile,
      handleFileChange,
   }
}

export default useFileUpload
