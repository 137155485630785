export const QUERY_KEYS = {
   GET_PROGRAMS: 'GET_PROGRAMS',
   GET_PROGRAM: 'GET_PROGRAM',
   GET_PROGRAM_FILTER: 'GET_PROGRAM_FILTER',
   GET_CHANNEL_LIST: 'GET_CHANNEL_LIST',
   GET_LEVEL_LIST: 'GET_LEVEL_LIST',
   GET_PARTNER: 'GET_PARTNER',
   GET_PARTNER_LIST: 'GET_PARTNER_LIST',
   GET_POINTS: 'GET_POINTS',
   GET_PARTNER_HISTORY: 'GET_PARTNER_HISTORY',
   GET_OPERATION_LIST: 'GET_OPERATION_LIST',
   GET_PROFILE: 'GET_PROFILE',
   BASE: 'BASE',
   REFRESH_TOKEN: 'REFRESH_TOKEN',
   GET_HOTEL_LIST: 'GET_HOTEL_LIST',
   GET_CONTRACT_LIST: 'GET_CONTRACT_LIST',
} as const
