import { useMemo } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import Title from '../common/Title'
import Card from '../common/Card'
import { Button, Table } from 'ui'
import { useIntl } from 'react-intl'
import { createDateFromISO } from 'utils/dates'
import qs from 'qs'
import { getCurrentValues, PartnerRow } from '../../utils/partnerUtils'
import Filters, { PartnerFilterForm } from './components/Filters'
import { plans } from '../../api/program'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faShareFromSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { useGetPartnerList } from '../../hooks/useGetPartnerList'
import { useMutation } from '@tanstack/react-query'
import { getPartnerExportList } from '../../api/partner'
import { useAppSelector } from '../../store/hooks'
export type Level = {
   id: number
   name: string
}

export type Channel = {
   id: number
   name: string
}
type LoyaltyPlanHotel = {
   id: number
   hotelId: number
   hotelName: string
   discount: number
   contracts: number[]
}

type Photo = {
   id: number
   basePath: string
   path: string
   title: string
   galleryType: string
}

type Benefit = {
   id: number
   hotels: number[]
   name: string
}
export type LoyaltyPlan = {
   id: number
   name: string
   level: Level[]
   isActive: boolean
   hasDiscount: boolean
   primaryColor: string
   headerBackground: string
   headerFontColor: string
   hotels: LoyaltyPlanHotel[]
   photos: Photo[]
   benefits: Benefit[]
}

export type Partner = {
   id: number
   account: string
   createdAt: string
   hotelId: number
   firstName: string
   lastName: string
   email: string
   phoneNumber: string
   maritalStatus: 'MARRIED' | 'SINGLE' | 'DIVORCED' | 'WIDOWED' | 'SEPARATED'
   dateOfBirth: string
   gender: 'FEMALE' | 'MALE' | 'OTHER'
   countryOfResidence: string
   registrationChannel: string
   isActive: true
   externalId: number
   loyaltyPlans: Array<
      plans & {
         points: number
         level: string
         loyaltyPlanName: string
         expirationDate: string
         loyaltyPlanId: number
         registrationDate: string
         registrationChannel: string
      }
   >
   points: number
   totalRevenue: number
   latestReservationDate: string
   state?: string
   municipality?: string
   allergies?: string
   bloodType?: string
   swimmingSkill?: boolean
   passion?: string
   operaProfileSourceId?: string
   typeBlood?: string
}

type Props = {}

export const Partners = (props: Props) => {
   const intl = useIntl()
   const navigate = useNavigate()
   const location = useLocation()
   const { locale } = useAppSelector((state) => state.base)
   const { data: partnerList, status } = useGetPartnerList(getCurrentValues(location.search))
   const { mutate } = useMutation({
      mutationFn: getPartnerExportList,
   })

   const columns = useMemo(
      () => [
         {
            header: intl.formatMessage({ id: 'partners.id' }),
            accessorKey: 'externalId',
            cell: ({ row }: { row: { original: PartnerRow } }) => {
               return <Link to={`${row.original.id}`}>{row.original.externalId} </Link>
            },
            size: 95,
         },
         {
            header: intl.formatMessage({ id: 'partners.partner' }),
            accessorKey: 'name',
            cell: ({ row }: { row: { original: PartnerRow } }) => {
               return (
                  <Link to={`${row.original.id}`}>
                     {`${row.original.firstName} ${row.original.lastName}`}
                     <div className="text-sm text-neutral-400">{row.original.email}</div>
                  </Link>
               )
            },
            size: 250,
         },
         {
            header: () => <div className="text-right">{intl.formatMessage({ id: 'partners.points' })}</div>,
            accessorKey: 'points',
            cell: ({ row }: { row: { original: PartnerRow } }) => (
               <div className="text-right">{row.original.points}</div>
            ),
         },
         {
            header: () => <div className="text-right">{intl.formatMessage({ id: 'partners.income' })}</div>,
            accessorKey: 'totalRevenue',
            cell: ({ row }: { row: { original: PartnerRow } }) => (
               <div className="text-right">{row.original.totalRevenue}</div>
            ),
         },
         {
            header: intl.formatMessage({ id: 'partners.lastOperation' }),
            accessorKey: 'lastOperation',
            cell: ({ row }: { row: { original: PartnerRow } }) => {
               const isoDate = createDateFromISO(row.original.latestReservationDate).setLocale(locale)
               return (
                  <div>
                     {isoDate.isValid ? isoDate.toFormat(intl.formatMessage({ id: 'date.format' })) : '---'}
                     {isoDate.isValid ? (
                        <div className="text-sm text-neutral-400">{`${isoDate.toFormat('HH:mm')} hrs`}</div>
                     ) : (
                        ''
                     )}
                  </div>
               )
            },
         },
      ],
      [intl, locale],
   )
   const currentValues = getCurrentValues(location.search)

   const onFilter = (data: PartnerFilterForm) => {
      const request = Object.assign({}, data)
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      ) //TODO: revisar si se soluciona el bug que encodea la coma https://github.com/ljharb/qs/issues/410
   }

   const onPageChange = (page: { page: number; size: number }) => {
      const request = Object.assign({}, currentValues, { ...page })
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      )
   }
   const onExport = () => {
      const request = {
         ...getCurrentValues(location.search),
      }
      mutate(request, {
         onSuccess: (response) => {
            let excellName = `members.xlsx`
            const blob = new Blob([response as unknown as BlobPart], {
               type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = excellName
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
         },
      })
   }
   const onImport = () => {
      navigate(`import`)
   }
   const onClickCreate = () => {
      navigate(`create`)
   }
   return (
      <>
         <Title
            className="flex justify-between gap-x-2  items-center "
            value={intl.formatMessage({ id: 'titles.partners' })}
         >
            <Button onClick={onClickCreate} className={'flex gap-x-2 items-center text-[12px] mr-4 '}>
               <FontAwesomeIcon icon={faUserPlus} className={'text-gray-500 text '} />
               {intl.formatMessage({ id: 'partner.create' })}
            </Button>
         </Title>
         <div className="flex flex-col gap-y-4 mx-4">
            <Card className="py-4">
               <Filters className="px-4" onSubmit={onFilter} defaultValues={currentValues} />
               <Table
                  fetching={status === 'pending'}
                  columns={columns}
                  data={partnerList?.items ?? []}
                  tableClassName="border-y"
                  footerClassName="px-4"
                  exportText={intl.formatMessage({ id: 'partner.export' })}
                  showTableResume
                  pagination={{
                     defaultPageSize: currentValues.size,
                     defaultPageIndex: currentValues.page,
                     totalSize: partnerList?.totalSize ?? 0,
                     onPageChange: onPageChange,
                  }}
               >
                  <div className="flex justify-between gap-x-2">
                     <Button onClick={onImport} className={'flex gap-x-2 items-center text-[12px]'}>
                        <FontAwesomeIcon icon={faDownload} className={'text-gray-500 text '} />
                        {intl.formatMessage({ id: 'partner.import' })}
                     </Button>
                     <Button onClick={onExport} className={'flex gap-x-2 items-center text-[12px]'}>
                        <FontAwesomeIcon icon={faShareFromSquare} className={'text-gray-500 text '} />
                        {intl.formatMessage({ id: 'partner.export' })}
                     </Button>
                  </div>
               </Table>
            </Card>
         </div>
      </>
   )
}
