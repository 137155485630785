import { useIntl } from 'react-intl'
import Title from '../../common/Title'
import Card from '../../common/Card'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Input, Select } from 'ui/fields'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker'
import { getStatusOptions } from '../../../utils/partnerUtils'
import { Button } from 'ui'
import MemberField from './MemberField'
import LevelField from './LevelField'
import { useMutation } from '@tanstack/react-query'
import { useGetPrograms } from '../../../hooks/useGetPrograms'
import { createOperation } from '../../../api/operation'
import { useAppSelector } from '../../../store/hooks'

type Props = {}
type Inputs = {
   loyaltyPlanId: string
   levelId: string
   operationType: string
   serviceType: string
   reservationUuid: string
   ReservationName: string
   memberId: string
   reservationCode: string
   reservationDate: Date
   reservationSubTotal: number
   currency: string
   reservationTotal: number
}
const AddOperation = (props: Props) => {
   const intl = useIntl()
   const navigate = useNavigate()
   const base = useAppSelector((state) => state.base)
   const programList = useGetPrograms()
   const { mutate: formSubmit } = useMutation({
      mutationFn: createOperation,
   })
   const {
      register,
      handleSubmit,
      watch,
      control,
      formState: { errors, dirtyFields },
   } = useForm<Inputs>({
      mode: 'onChange',
      defaultValues: {
         loyaltyPlanId: '',
         levelId: '',
         operationType: '',
         serviceType: '',
         reservationUuid: '',
         ReservationName: '',
      },
   })
   const loyaltyPlanId = watch('loyaltyPlanId')

   const onSubmit = (data: Inputs) => {
      let request = {
         account: (base.account?.code as unknown as string) || '',
         loyaltyPlanId: parseInt(data.loyaltyPlanId),
         levelId: parseInt(data.levelId),
         operationType: data.operationType,
         hotelId: '',
         hotelName: '',
         serviceType: data.serviceType,
         memberId: data.memberId,
         // reservationUuid: data.reservationUuid,
         reservationName: data.ReservationName,
         reservationCode: data.reservationCode,
         reservationDate: data.reservationDate as unknown as string,
         reservationSubTotal: parseInt(data.reservationSubTotal as unknown as string),
         reservationTotal: parseInt(data.reservationTotal as unknown as string),
         currency: data.currency,
         pointsRedeemed: null,
      }
      formSubmit(request, {
         onSuccess: (response) => {
            navigate(`/${base.account?.code}/operations`)
         },
      })
   }
   const handleClick = () => {
      navigate(`/${base.account?.code}/operations`)
   }
   if (programList.isLoading || programList.isPending) {
      return (
         <div className="absolute inset-0 bg-neutral-400 opacity-25 flex items-center justify-center">
            <FontAwesomeIcon icon={faSpinner} spin size="3x" />
         </div>
      )
   }
   return (
      <>
         <Title value={intl.formatMessage({ id: 'operations.create' })} />
         <div className="flex flex-col gap-y-4 mx-4 ">
            <Card className="p-4 w-full ">
               <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                     name={`loyaltyPlanId`}
                     control={control}
                     rules={{ required: intl.formatMessage({ id: 'operation.errors.loyaltyPlanId' }) }}
                     render={({ field: { value, onChange } }) => (
                        <Select
                           label={intl.formatMessage({ id: 'operation.form.loyaltyPlanId' })}
                           value={value}
                           onChange={onChange}
                           error={errors?.loyaltyPlanId?.message}
                           showErrorText={true}
                           className="mb-4"
                           isTouched={dirtyFields.loyaltyPlanId}
                        >
                           <Select.Option value="">--</Select.Option>
                           {programList?.data?.items?.map((program) => (
                              <Select.Option key={program.id} value={program.id}>
                                 {program.name}
                              </Select.Option>
                           ))}
                        </Select>
                     )}
                  />
                  <MemberField
                     loyaltyPlanId={loyaltyPlanId}
                     control={control}
                     errors={errors}
                     isTouched={dirtyFields.memberId}
                  />
                  <LevelField
                     loyaltyPlanId={loyaltyPlanId}
                     control={control}
                     errors={errors}
                     isTouched={dirtyFields.levelId}
                  />
                  <Controller
                     name={`operationType`}
                     control={control}
                     rules={{ required: intl.formatMessage({ id: 'operation.errors.operationType' }) }}
                     render={({ field: { value, onChange } }) => (
                        <Select
                           label={intl.formatMessage({ id: 'operation.form.operationType' })}
                           value={value}
                           onChange={onChange}
                           error={errors?.operationType?.message}
                           showErrorText={true}
                           className="mb-4"
                           isTouched={dirtyFields.operationType}
                        >
                           <Select.Option value="">--</Select.Option>
                           {getStatusOptions(['ACCUMULATION', 'REDEMPTION']).map((type) => (
                              <Select.Option key={type.code} value={type.code}>
                                 {intl.formatMessage({ id: `operation.form.operationType.${type.value}` })}
                              </Select.Option>
                           ))}
                        </Select>
                     )}
                  />
                  <Input
                     type="text"
                     autoFocus
                     label={intl.formatMessage({ id: `operation.form.serviceType` })}
                     {...register('serviceType', {
                        required: intl.formatMessage({ id: `operation.errors.serviceType` }),
                     })}
                     error={errors?.serviceType?.message}
                     isTouched={dirtyFields.serviceType}
                     containerClassName="mb-4"
                  />
                  <Input
                     type="text"
                     autoFocus
                     label={intl.formatMessage({ id: `operation.form.reservationUuid` })}
                     {...register('reservationUuid', {})}
                     error={errors?.reservationUuid?.message}
                     isTouched={dirtyFields.reservationUuid}
                     containerClassName="mb-4"
                  />
                  <Input
                     type="text"
                     autoFocus
                     label={intl.formatMessage({ id: `operation.form.ReservationName` })}
                     {...register('ReservationName', {
                        required: intl.formatMessage({ id: `operation.errors.ReservationName` }),
                     })}
                     error={errors?.ReservationName?.message}
                     isTouched={dirtyFields.ReservationName}
                     containerClassName="mb-4"
                  />

                  <Input
                     type="text"
                     autoFocus
                     label={intl.formatMessage({ id: `operation.form.reservationCode` })}
                     {...register('reservationCode', {})}
                     error={errors?.reservationCode?.message}
                     isTouched={dirtyFields.reservationCode}
                     containerClassName="mb-4"
                  />
                  <Controller
                     name="reservationDate"
                     control={control}
                     rules={{ required: intl.formatMessage({ id: 'operation.errors.reservationDate' }) }}
                     render={({ field }) => (
                        <div className={`mb-4 ${errors?.reservationDate?.message ? 'has-error' : ''}`}>
                           <label className="block mb-2 font-semibold">
                              {intl.formatMessage({ id: `operation.form.reservationDate` })}
                           </label>
                           <DatePicker
                              placeholderText="Select your birthdate"
                              closeOnScroll
                              onChange={(date) => field.onChange(date)}
                              onBlur={field.onBlur}
                              selected={field.value as unknown as Date}
                              dateFormat="dd/MM/yyyy"
                              showIcon
                              isClearable
                              className={`w-full border border-gray-300 rounded-md p-2 ${
                                 errors?.reservationDate?.message ? 'form-control' : ''
                              }`}
                           />
                           {errors?.reservationDate?.message && (
                              <div className="text-red-500 text-[12px] mt-1">{errors?.reservationDate?.message}</div>
                           )}
                        </div>
                     )}
                  />
                  <Controller
                     name={`currency`}
                     control={control}
                     rules={{ required: intl.formatMessage({ id: 'operation.errors.currency' }) }}
                     render={({ field: { value, onChange } }) => (
                        <Select
                           label={intl.formatMessage({ id: 'operation.form.currency' })}
                           value={value}
                           onChange={onChange}
                           error={errors?.currency?.message}
                           showErrorText={true}
                           className="mb-4"
                           isTouched={dirtyFields.currency}
                        >
                           <Select.Option value="">--</Select.Option>
                           {getStatusOptions(['MXN', 'USD']).map((currency) => (
                              <Select.Option key={currency.code} value={currency.code}>
                                 {intl.formatMessage({ id: `operation.form.currency.${currency.value}` })}
                              </Select.Option>
                           ))}
                        </Select>
                     )}
                  />

                  <Input
                     type="number"
                     autoFocus
                     label={intl.formatMessage({ id: `operation.form.reservationSubTotal` })}
                     {...register('reservationSubTotal', {
                        required: intl.formatMessage({ id: 'operation.errors.reservationSubTotal' }),
                     })}
                     error={errors?.reservationSubTotal?.message}
                     isTouched={dirtyFields.reservationSubTotal}
                     containerClassName="mb-4"
                  />

                  <Input
                     type="number"
                     autoFocus
                     label={intl.formatMessage({ id: `operation.form.reservationTotal` })}
                     {...register('reservationTotal', {
                        required: intl.formatMessage({ id: 'operation.errors.reservationTotal' }),
                     })}
                     error={errors?.reservationTotal?.message}
                     isTouched={dirtyFields.reservationTotal}
                     containerClassName="mb-4"
                  />
                  <div className="flex justify-end mt-4 gap-x-4 mt-2">
                     <Button bsStyle="default" onClick={handleClick}>
                        Cancelar
                     </Button>
                     <Button type="submit" bsStyle="success">
                        Aceptar
                     </Button>
                  </div>
               </form>
            </Card>
         </div>
      </>
   )
}

export default AddOperation
