// eslint-disable-next-line
export default {
   'operations.reservation': 'Reservación',
   'operations.date': 'Fecha',
   'operations.creationDate': 'Fecha de creacion',
   'operations.reservation-date': 'Fecha de reservacion',
   'operations.partner': 'Socio',
   'operations.accumulatedPoints': 'Puntos acumulados',
   'operations.redeemedPoints': 'Puntos redimidos',
   'operations.dates': 'Fechas',
   'operations.other': 'Otra',
   'operations.any': 'Cualquiera',
   'operations.selectDate': 'Seleccionar fecha',
   'operations.export': 'Exportar',
   'operations.import': 'Importar operaciones',
   'operations.importHelpText': 'Arrastra o ingresa el archivo con los usuarios a importar.',

   'operations.create': 'Crear Operación',
   'operation.form.memberId': 'Socio',
   'operation.errors.memberId': 'El socio es requerido',
   'operation.errors.loyaltyPlanId': 'El plan de lealtad es requerido',
   'operation.form.loyaltyPlanId': 'Plan de lealtad',
   'operation.form.levelId': 'Nivel',
   'operation.errors.levelId': 'El nivel es requerido',
   'operation.errors.operationType': 'El tipo de operación es requerido',
   'operation.form.operationType': 'Tipo de operación',
   'operation.errors.serviceType': 'El tipo de servicio es requerido',
   'operation.form.serviceType': 'Tipo de servicio',
   'operation.form.reservationUuid': 'UUID de la reservación',
   'operation.form.ReservationName': 'Nombre de la reservación',
   'operation.errors.ReservationName': 'El nombre de la reservación es requerido',
   'operation.form.reservationCode': 'Código de la reservación',
   'operation.errors.currency': 'La moneda es requerida',
   'operation.form.currency': 'Moneda',
   'operation.form.reservationDate': 'Fecha de reservación',
   'operation.errors.reservationDate': 'La fecha de reservación es requerida',
   'operation.errors.reservationSubTotal': 'El subtotal de la reservación es requerido',
   'operation.form.reservationSubTotal': 'Subtotal',
   'operation.form.reservationTotal': 'Total',
   'operation.errors.reservationTotal': 'El total de la reservación es requerido',
   'operation.form.operationType.redemption': 'redencion',
   'operation.form.operationType.accumulation': 'acumulacion',
   'operation.form.currency.mxn': 'MXN',
   'operation.form.currency.usd': 'USD',
}
