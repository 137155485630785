import React from 'react'
import { useIntl } from 'react-intl'

export interface Birthdate {
   day: string
   month: string
   year: string
}

interface BirthdatePickerProps {
   value: Birthdate
   onChange: (value: Birthdate) => void
   className?: string
}

const BirthdatePicker: React.FC<BirthdatePickerProps> = ({ value, onChange, className }) => {
   const days = Array.from({ length: 31 }, (_, i) => i + 1)
   const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
   ]
   const years = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i)

   const handleDateChange = (type: keyof Birthdate, val: string) => {
      onChange({ ...value, [type]: val })
   }
   const intl = useIntl()
   return (
      <div className={`flex space-x-4 ${className}`}>
         <select
            className="p-2 border rounded-md w-full"
            value={value.day || ''}
            onChange={(e) => handleDateChange('day', e.target.value)}
         >
            <option value="">{intl.formatMessage({ id: 'partner.form.day' })}</option>
            {days.map((d) => (
               <option key={d} value={String(d)}>
                  {d}
               </option>
            ))}
         </select>

         <select
            className="p-2 border rounded-md w-full"
            value={value.month || ''}
            onChange={(e) => handleDateChange('month', e.target.value)}
         >
            <option value="">{intl.formatMessage({ id: 'partner.form.month' })}</option>
            {months.map((m, index) => (
               <option key={index} value={String(index + 1)}>
                  {intl.formatMessage({ id: `partner.form.${m}` })}
               </option>
            ))}
         </select>

         <select
            className="p-2 border rounded-md w-full"
            value={value.year || ''}
            onChange={(e) => handleDateChange('year', e.target.value)}
         >
            <option value="">{intl.formatMessage({ id: 'partner.form.year' })}</option>
            {years.map((y) => (
               <option key={y} value={String(y)}>
                  {y}
               </option>
            ))}
         </select>
      </div>
   )
}

export default BirthdatePicker
