// eslint-disable-next-line
export default {
   'partners.id': 'ID',
   'partners.partner': 'Socios',
   'partners.levelProgram': 'Nivel / Programa',
   'partners.channel': 'Canal de registro',
   'partners.points': 'Puntos',
   'partners.income': 'Ingresos generados',
   'partners.lastOperation': 'Última operación',
   'partners.program': 'Programa',
   'partners.status': 'Estatus',
   'partner.importHelpText': 'Arrastra o ingresa el archivo con los usuarios a importar.',

   'partners.level': 'Nivel',
   'date.format': 'ccc. dd LLL. yy',
   'partner.information': 'Información del socio',
   'partner.history': 'Historial de actividades',
   'partner.membership.information': 'Información de membresía',
   'partner.name': 'Nombre',
   'partner.email': 'Correo electrónico',
   'partner.status': 'Estado civil',
   'partner.sex': 'Sexo',
   'partner.lastName': 'Apellidos',
   'partner.phone': 'Teléfono',
   'partner.birthday': 'Fecha de nacimiento',
   'partner.country': 'País de residencia',
   'partner.points': 'Puntos',
   'partner.program': 'Programa',
   'partner.registrationChannel': 'Canal de registro',
   'partner.registrationDate': 'Fecha de registro',
   'partner.level': 'Nivel',
   'partner.numberOfPoints': '{points}puntos',
   'partner.from': 'Socio desde el {date}',
   'partner.sex.FEMALE': 'Mujer',
   'partner.sex.MALE': 'Hombre',
   'partner.status.MARRIED': 'Casado',
   'partner.status.SINGLE': 'Soltero',
   'partner.historyItem': '{points} por {service} en {name}',
   'partner.points.accumulated': '{points} puntos acumulados',
   'partner.points.redeemed': '{points} puntos redimidos',
   'partner.all.operations': 'Ver todas las operaciones',
   'partner.export': 'Exportar',
   'partner.import': 'Importar socios',
   'partner.createAt': 'Creado el {date}',
   'partner.create': 'Nuevo socio',
   'partner.create.tittle': 'Nuevo socio',
   'partner.form.firstName': 'Nombre',
   'partner.form.lastName': 'Apellido',
   'partner.form.email': 'Correo electrónico',
   'partner.form.status': 'Estatus',
   'partner.form.phonenumber': 'Numero de telefono',
   'partner.form.birthday': 'Fecha de nacimiento',
   'partner.form.country': 'País de residencia',
   'partner.form.phone.label.info': 'Teléfono con clave lada',
   'partner.form.areaCode': 'Código de país',
   'partner.errors.firstName': 'Escribe tu(s) nombre(s)',
   'partner.errors.lastName': 'Escribe tus apellidos',
   'partner.errors.email': 'Escribe tu email',
   'partner.errors.emailFormat': 'Revisa que el formato de tu email esté correcto (micorreo@domain.com).',
   'partner.errors.areaCode': 'Escribe la Lada de tu número telefónico',
   'partner.errors.phoneNumber': 'Escribe tu número telefónico',
   'partner.errors.status': 'Requiere este campo',
   'partner.errors.loyaltyplanId': 'Requiere este campo',
   'partner.errors.levelId': 'Requiere este campo',
   'partner.errors.birthday': 'Selecciona la fecha de nacimiento',
   'partner.errors.country': 'Selecciona el país de residencia',
   'partner.status.single': 'Soltero',
   'partner.status.married': 'Casado',
   'partner.status.divorced': 'Divorciado',
   'partner.status.widowed': 'Viudo',
   'partner.status.separated': 'Separado',
   'partner.status.civil_union': 'Union civil',
   'partner.errors.gender': 'Requiere este campo',
   'partner.form.gender': 'Sexo',
   'partner.gender.male': 'Hombre',
   'partner.gender.female': 'Mujer',
   'partner.form.registrationChannel': 'Canal de registro',
   'partner.errors.registrationChannel': 'Requiere este campo',
   'partner.form.state': 'Estado',
   'partner.form.city': 'Delegacion/Municipio',
   'partner.form.passion': '¿Cual es tu pasion?',
   'partner.form.allergies': 'Alergias',
   'partner.form.typeBlood': 'Tipo de sangre',
   'partner.form.swimming': '¿Sabes nadar?',
   'partner.form.programs': 'Programas',
   'partner.form.level': 'Nivel',
   'partner.form.channel': 'Canal de registro',
   'partner.form.add': 'Agregar otro',
   'partner.title.additionalInformation': '{plan}: Información adicional',
   'partner.form.sourceId': 'Opera Profile Source ID',
   'partner.errors.birthday.invalid': 'Llena todos los campos de nacimiento',
   'partner.errors.state': 'Escribe tu estado',
   'partner.errors.city': 'Escribe tu ciudad',
   'partner.form.day': 'Día',
   'partner.form.month': 'Mes',
   'partner.form.year': 'Año',
   'partner.errors.required': 'Requiere este campo',
   'partner.title.additional': 'Información adicional',
   'partner.tittle.loyaltyPlans': 'Programas de lealtad',
   'partner.tittle.personalInformation': 'Informacion personal',
   'partner.form.January': 'Enero',
   'partner.form.February': 'Febrero',
   'partner.form.March': 'Marzo',
   'partner.form.April': 'Abril',
   'partner.form.May': 'Mayo',
   'partner.form.June': 'Junio',
   'partner.form.July': 'Julio',
   'partner.form.August': 'Agosto',
   'partner.form.September': 'Septiembre',
   'partner.form.October': 'Octubre',
   'partner.form.November': 'Noviembre',
   'partner.form.December': 'Diciembre',
}
