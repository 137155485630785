import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Select } from 'ui/fields'
import useGetLevelList from '../../../hooks/useGetLevelList'
import { useEffect } from 'react'
import { Inputs } from './AddPartner'
interface Props {
   index: number
   control: Control<Inputs, any>
   errors: any
   dirtyFields: any
   loyaltyplanId?: string
   setValue: UseFormSetValue<any>
}
const LevelIdField = (props: Props) => {
   const { index, control, errors, dirtyFields, loyaltyplanId, setValue } = props
   const intl = useIntl()
   const { data: levels, isLoading: levelStatus } = useGetLevelList({ loyaltyPlanIds: loyaltyplanId })
   useEffect(() => {
      if (levels && levels.totalSize === 1 && levels.items.length === 1) {
         setValue(`plans.${index}.levelId`, levels.items[0].id)
      }
   }, [levels, control, index, setValue])
   return (
      <>
         <Controller
            name={`plans.${index}.levelId`}
            control={control}
            rules={{
               required: intl.formatMessage({ id: 'partner.errors.levelId' }),
               validate: (value) => {
                  return value ? true : intl.formatMessage({ id: 'partner.errors.levelId' })
               },
            }}
            render={({ field: { value, onChange } }) => (
               <Select
                  boldLabel={false}
                  disabled={levelStatus}
                  label={intl.formatMessage({ id: 'partner.form.level' })}
                  value={value}
                  onChange={(i: string) => onChange(i)}
                  error={errors?.plans?.[index]?.levelId?.message}
                  showErrorText={true}
                  className="mb-4 w-full text-gray-700"
                  isTouched={dirtyFields.plans?.[index]?.levelId}
               >
                  <Select.Option value="">Seleccionar</Select.Option>
                  {levels &&
                     levels.items.map((plan) => (
                        <Select.Option key={plan.id} value={plan.name}>
                           {plan.name}
                        </Select.Option>
                     ))}
               </Select>
            )}
         />
      </>
   )
}

export default LevelIdField
