import React from 'react'
import Title from '../../common/Title'
import { useIntl } from 'react-intl'
import Card from '../../common/Card'
import { useForm, Controller, useFieldArray, useWatch } from 'react-hook-form'
import { Input, Select } from 'ui/fields'
import { emailRegex } from 'utils/validation'
import 'react-datepicker/dist/react-datepicker.css'
import { Button } from 'ui'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { useGetChannelList } from '../../../hooks/useGetChannelList'
import { createPartner } from '../../../api/partner'
import { useAppSelector } from '../../../store/hooks'
import { useGetCountries } from '../../../hooks/useGetCountries'
import PhoneCodeSelector from './PhoneCodeSelector'
import BirthdatePicker, { Birthdate } from './BirthdatePicker'
import useGetPrograms from '../../../hooks/useGetPrograms'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import LevelIdField from './LevelIdField'
import { AdditionalInformationType } from '../../../api/program'

export type Inputs = {
   firstName: string
   lastName: string
   email: string
   phoneNumber: string
   dateOfBirth: string
   registrationChannel: string
   plans: Array<{
      loyaltyplanId: string
      levelId: string
      registrationChannel: string
   }>
   maritalStatus?: string
   operaProfileSourceId?: string
   passion?: string
   allergies?: string
   bloodType?: string
   swimmingSkill?: string
   code: string
   state: string
   countryOfResidence: string
   municipality: string
}
const AddParter = () => {
   const intl = useIntl()
   const { data: channels, isLoading: isLoadingChannels } = useGetChannelList()
   const { data: plans, isLoading: isLoadingPlans } = useGetPrograms()
   const { data, isLoading } = useGetCountries()
   const {
      register,
      handleSubmit,
      control,
      setValue,
      formState: { errors, dirtyFields },
   } = useForm<Inputs>({
      mode: 'onChange',
      defaultValues: {
         firstName: '',
         lastName: '',
         email: '',
         phoneNumber: '',
         dateOfBirth: '',
         registrationChannel: '',
         plans: [
            {
               loyaltyplanId: plans?.totalSize === 1 ? plans?.items[0].id.toString() : '',
               levelId: '',
               registrationChannel: channels?.totalSize === 1 ? channels?.items[0].name : '',
            },
         ],
         code: 'MX',
         state: '',
         countryOfResidence: '',
         swimmingSkill: '',
      },
   })
   const { fields, append, remove } = useFieldArray({
      control,
      name: 'plans' as never,
   })
   const navigate = useNavigate()
   const base = useAppSelector((state) => state.base)
   const { mutate: submitForm } = useMutation({
      mutationFn: createPartner,
   })
   const plansForm = useWatch({ control, name: 'plans' })
   const controlledFields = fields.map((field, index) => {
      return {
         ...field,
         ...plansForm[index],
      }
   })
   const selectedPlans = plansForm.map((formItem) => {
      return plans?.items.find((plan) => plan.id === parseInt(formItem.loyaltyplanId))
   })

   const onSubmit = (data: Inputs) => {
      const { plans, code, phoneNumber, dateOfBirth, swimmingSkill, ...rest } = data
      const dateStr = dateOfBirth
      const [year, month, day] = dateStr.split('-')
      const formattedMonth = month.padStart(2, '0')
      const formattedDay = day.padStart(2, '0')
      const formattedDate = `${year}-${formattedMonth}-${formattedDay}`
      const formatPhone = `${code} ${phoneNumber}`
      const plansFormat = plans.map((plan) => ({
         loyaltyPlanId: parseInt(plan.loyaltyplanId, 10),
         levelName: plan.levelId,
         registrationChannel:
            (channels && channels.items?.find((i) => i.id === parseInt(plan.registrationChannel, 10))?.name) || '',
      }))
      const isSwimming = formatSwimming(swimmingSkill)
      let request = {
         ...rest,
         phoneNumber: formatPhone,
         dateOfBirth: formattedDate,
         plans: plansFormat,
         ...(isSwimming && { swimmingSkill: isSwimming }),
      }
      submitForm(request, {
         onSuccess: () => {
            navigate(`/${base.account?.code}/partners`)
         },
      })
   }
   const handleClick = () => {
      navigate(`/${base.account?.code}/partners`)
   }
   const parseBirthdate = (value: string): Birthdate => {
      const [year, month, day] = value.split('-')
      return { day, month, year }
   }
   const formatBirthdate = (birthdate: Birthdate): string => {
      return `${birthdate.year}-${birthdate.month}-${birthdate.day}`
   }

   const formatSwimming = (value?: string) => {
      return value === 'Si' ? true : false
   }
   const validateOptions = (id: number, controlledFields: any, index: number) => {
      const plan = controlledFields.find((i: any) => i.loyaltyplanId === id)
      return plan ? true : false
   }

   const renderAdditionalFields = (additionalFields: AdditionalInformationType[]) => {
      return (
         <div className="flex flex-wrap">
            {additionalFields.map((field, index) => (
               <div key={field.name} className="w-1/2 mb-4 px-2">
                  {field.type === 'String' && (
                     <Input
                        boldLabel={false}
                        type="text"
                        autoFocus={index === 0}
                        label={field.label}
                        {...register(field.name as keyof Inputs, {
                           required: field.required ? intl.formatMessage({ id: `partner.errors.required` }) : false,
                        })}
                        error={errors?.[field.name as keyof Inputs]?.message}
                        isTouched={dirtyFields?.[field.name as keyof Inputs] as boolean}
                        containerClassName="w-full text-gray-700"
                     />
                  )}
                  {field.type === 'select' && (
                     <Controller
                        name={field.name as keyof Inputs}
                        control={control}
                        rules={{
                           required: field.required ? intl.formatMessage({ id: `partner.errors.required` }) : false,
                        }}
                        render={({ field: { value, onChange } }) => (
                           <Select
                              boldLabel={false}
                              label={field.label}
                              value={typeof value === 'string' || typeof value === 'number' ? value : ''}
                              onChange={onChange}
                              error={errors?.[field.name as keyof Inputs]?.message}
                              isTouched={dirtyFields?.[field.name as keyof Inputs] as boolean}
                              showErrorText={field.required}
                              className="w-full text-gray-600"
                           >
                              <Select.Option value="">Seleccionar</Select.Option>
                              {field.values.map((optionValue) => (
                                 <Select.Option key={optionValue} value={optionValue}>
                                    {optionValue}
                                 </Select.Option>
                              ))}
                           </Select>
                        )}
                     />
                  )}
                  {field.type === 'boolean' && (
                     <Controller
                        name={field.name as keyof Inputs}
                        control={control}
                        rules={{
                           required: field.required ? intl.formatMessage({ id: `partner.errors.required` }) : false,
                        }}
                        render={({ field: { value, onChange } }) => (
                           <Select
                              boldLabel={false}
                              label={field.label}
                              value={typeof value === 'string' || typeof value === 'number' ? value : ''}
                              onChange={onChange}
                              error={errors?.[field.name as keyof Inputs]?.message}
                              isTouched={dirtyFields?.[field.name as keyof Inputs] as boolean}
                              showErrorText={field.required}
                              className="w-full text-gray-700"
                           >
                              <Select.Option value="">Seleccionar</Select.Option>
                              {field.values.map((optionValue) => (
                                 <Select.Option key={optionValue} value={optionValue}>
                                    {optionValue}
                                 </Select.Option>
                              ))}
                           </Select>
                        )}
                     />
                  )}
               </div>
            ))}
         </div>
      )
   }
   if (isLoading || isLoadingChannels || isLoadingPlans) {
      return <div>Loading...</div>
   }

   return (
      <>
         <Title value={intl.formatMessage({ id: 'partner.create.tittle' })} />
         <div className='className="flex flex-col gap-y-4 mx-4 "'>
            <Card>
               <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="p-5">
                     <h3 className="font-semibold text-lg mb-6 text-gray-700">
                        {intl.formatMessage({ id: `partner.tittle.personalInformation` })}
                     </h3>
                     <div className="flex gap-x-8 w-full">
                        <Input
                           type="text"
                           autoFocus
                           label={intl.formatMessage({ id: `partner.form.firstName` })}
                           {...register('firstName', {
                              required: intl.formatMessage({ id: `partner.errors.firstName` }),
                           })}
                           boldLabel={false}
                           error={errors?.firstName?.message}
                           isTouched={dirtyFields.firstName}
                           containerClassName="mb-4 w-full text-gray-700"
                        />
                        <Input
                           type="text"
                           label={intl.formatMessage({ id: `partner.form.lastName` })}
                           {...register('lastName', {
                              required: intl.formatMessage({ id: `partner.errors.lastName` }),
                           })}
                           boldLabel={false}
                           error={errors?.lastName?.message}
                           isTouched={dirtyFields.lastName}
                           containerClassName="mb-4 w-full text-gray-700"
                        />
                     </div>

                     <div className="flex gap-x-8 w-full">
                        <Input
                           boldLabel={false}
                           type="text"
                           label={intl.formatMessage({ id: `partner.form.email` })}
                           {...register('email', {
                              required: intl.formatMessage({ id: `partner.errors.email` }),
                              pattern: {
                                 value: emailRegex,
                                 message: intl.formatMessage({ id: 'partner.errors.emailFormat' }),
                              },
                           })}
                           error={errors?.email?.message}
                           isTouched={dirtyFields.email}
                           containerClassName="mb-4 w-full text-gray-700"
                        />

                        <Input
                           boldLabel={false}
                           type="number"
                           label={intl.formatMessage({ id: 'partner.form.phonenumber' })}
                           {...register('phoneNumber', {
                              required: intl.formatMessage({ id: 'partner.errors.phoneNumber' }),
                           })}
                           error={errors?.phoneNumber?.message}
                           isTouched={dirtyFields.phoneNumber}
                           containerClassName="mb-4 w-full text-gray-700"
                           inputGroupAling="left"
                           inputGroup={
                              data &&
                              data.length && (
                                 <PhoneCodeSelector countries={data} control={control} register={register} />
                              )
                           }
                           helpText={
                              <div className="flex">
                                 <div className="w-[110px]">{intl.formatMessage({ id: 'partner.form.areaCode' })}</div>
                                 <div>{intl.formatMessage({ id: 'partner.form.phone.label.info' })}</div>
                              </div>
                           }
                        />
                     </div>

                     <Controller
                        name="dateOfBirth"
                        control={control}
                        rules={{
                           required: intl.formatMessage({ id: 'partner.errors.birthday' }),
                           validate: (value) => {
                              const [year, month, day] = value.split('-')
                              if (!day || !month || !year) {
                                 return intl.formatMessage({ id: 'partner.errors.birthday.invalid' })
                              }
                              return true
                           },
                        }}
                        render={({ field }) => {
                           const birthdateValue = field.value
                              ? parseBirthdate(field.value)
                              : { day: '', month: '', year: '' }

                           return (
                              <div className={`mb-4 ${errors?.dateOfBirth?.message ? 'has-error' : ''}`}>
                                 <label className="block mb-2 text-gray-700">
                                    {intl.formatMessage({ id: `partner.form.birthday` })}
                                 </label>
                                 <BirthdatePicker
                                    className="mb-4 w-2/4 pr-4"
                                    value={birthdateValue}
                                    onChange={(newBirthdate) => field.onChange(formatBirthdate(newBirthdate))}
                                 />
                                 {errors?.dateOfBirth?.message && (
                                    <div className="text-red-500 error-block text-[12px] mt-1">
                                       {errors?.dateOfBirth?.message}
                                    </div>
                                 )}
                              </div>
                           )
                        }}
                     />
                     <div className="flex gap-x-8 w-full">
                        <Input
                           boldLabel={false}
                           type="text"
                           label={intl.formatMessage({ id: `partner.form.state` })}
                           {...register('state', { required: intl.formatMessage({ id: `partner.errors.state` }) })}
                           error={errors?.state?.message}
                           isTouched={dirtyFields.state}
                           containerClassName="mb-4 w-full text-gray-700"
                        />
                        <Input
                           boldLabel={false}
                           type="text"
                           label={intl.formatMessage({ id: `partner.form.city` })}
                           {...register('municipality', {
                              required: intl.formatMessage({ id: `partner.errors.city` }),
                           })}
                           error={errors?.municipality?.message}
                           isTouched={dirtyFields.municipality}
                           containerClassName="mb-4 w-full text-gray-700"
                        />
                     </div>
                  </div>
                  <div className="border mt-3 "></div>
                  <div className="p-5">
                     <h3 className="font-semibold text-lg mb-6 text-gray-700">
                        {intl.formatMessage({ id: 'partner.tittle.loyaltyPlans' })}
                     </h3>
                     <div className="">
                        {controlledFields.map((field, index) => {
                           return (
                              <React.Fragment key={index}>
                                 <div className="flex space-x-5 w-full items-center">
                                    <Controller
                                       name={`plans.${index}.loyaltyplanId`}
                                       control={control}
                                       rules={{
                                          required: intl.formatMessage({ id: 'partner.errors.loyaltyplanId' }),
                                          validate: (value) => {
                                             return value
                                                ? true
                                                : intl.formatMessage({ id: 'partner.errors.loyaltyplanId' })
                                          },
                                       }}
                                       render={({ field: { value, onChange } }) => (
                                          <Select
                                             label={intl.formatMessage({ id: 'partner.form.programs' })}
                                             value={parseInt(value) || value}
                                             onChange={(i: string) => onChange(parseInt(i))}
                                             error={errors?.plans?.[index]?.loyaltyplanId?.message}
                                             showErrorText={true}
                                             className="mb-4 w-full text-gray-700"
                                             boldLabel={false}
                                             isTouched={dirtyFields.plans?.[index].loyaltyplanId}
                                          >
                                             <Select.Option value="">Seleccionar</Select.Option>
                                             {plans &&
                                                plans.items.map((plan, index) => (
                                                   <Select.Option
                                                      key={plan.id}
                                                      value={plan.id}
                                                      disabled={validateOptions(plan.id, controlledFields, index)}
                                                   >
                                                      {plan.name}
                                                   </Select.Option>
                                                ))}
                                          </Select>
                                       )}
                                    />
                                    <LevelIdField
                                       index={index}
                                       control={control}
                                       errors={errors}
                                       dirtyFields={dirtyFields}
                                       loyaltyplanId={field?.loyaltyplanId}
                                       setValue={setValue}
                                    />

                                    <Controller
                                       name={`plans.${index}.registrationChannel`}
                                       control={control}
                                       rules={{
                                          required: intl.formatMessage({ id: 'partner.errors.registrationChannel' }),
                                          validate: (value) => {
                                             return value
                                                ? true
                                                : intl.formatMessage({ id: 'partner.errors.loyaltyplanId' })
                                          },
                                       }}
                                       render={({ field: { value, onChange } }) => (
                                          <Select
                                             boldLabel={false}
                                             label={intl.formatMessage({ id: 'partner.form.channel' })}
                                             value={parseInt(value) || value}
                                             onChange={(i: string) => onChange(parseInt(i))}
                                             error={errors?.plans?.[index]?.registrationChannel?.message}
                                             showErrorText={true}
                                             className="mb-4 w-full text-gray-700"
                                             isTouched={dirtyFields.plans?.[index].registrationChannel}
                                          >
                                             <Select.Option value="">Seleccionar</Select.Option>
                                             {channels &&
                                                channels.items.map((plan) => (
                                                   <Select.Option key={plan.id} value={plan.id}>
                                                      {plan.name}
                                                   </Select.Option>
                                                ))}
                                          </Select>
                                       )}
                                    />
                                    {fields.length > 1 && (
                                       <span className="cursor-pointer" onClick={() => remove(index)}>
                                          <FontAwesomeIcon icon={faTrash} className={'text-gray-500 text '} />
                                       </span>
                                    )}
                                 </div>
                                 {index === fields.length - 1 && (
                                    <Button
                                       type="button"
                                       bsStyle="default"
                                       className="flex gap-x-2 items-center text-[12px]"
                                       disabled={controlledFields.length === plans?.items.length}
                                       onClick={() =>
                                          append({
                                             loyaltyplanId: '',
                                             levelId: '',
                                             registrationChannel: '',
                                          })
                                       }
                                    >
                                       <FontAwesomeIcon icon={faPlus} className={'text-gray-500 text '} />
                                       {intl.formatMessage({ id: 'partner.form.add' })}
                                    </Button>
                                 )}
                              </React.Fragment>
                           )
                        })}
                     </div>
                  </div>
                  {selectedPlans.map((plan, planIndex) => {
                     if (!plan) return null
                     const additionalFields = plan.additionalInformationTypes || []
                     if (!additionalFields.length) {
                        return null
                     }
                     return (
                        <React.Fragment key={planIndex}>
                           <div className="border mt-3 "></div>
                           <div className="p-5">
                              <h3 className="font-semibold text-lg mb-6 text-gray-700">
                                 {intl.formatMessage(
                                    { id: `partner.title.additionalInformation` },
                                    { plan: plan?.name },
                                 )}
                              </h3>
                              {renderAdditionalFields(additionalFields)}
                           </div>
                        </React.Fragment>
                     )
                  })}
                  <div className="border mt-3 "></div>
                  <div className="flex justify-start gap-x-4 p-5">
                     <Button
                        type="submit"
                        bsStyle="default"
                        buttonStyle={{
                           paddingRight: '25px',
                           paddingLeft: '25px',
                           color: 'white',
                           backgroundColor: 'rgb(217 119 6 / var(--tw-bg-opacity))',
                        }}
                     >
                        Aceptar
                     </Button>
                     <Button bsStyle="default" onClick={handleClick}>
                        Cancelar
                     </Button>
                  </div>
               </form>
            </Card>
         </div>
      </>
   )
}

export default AddParter
