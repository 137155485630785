import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Select } from 'ui/fields'
import { GetPartnerListRequest } from '../../../api/partner'
import { useGetPartnerList } from '../../../hooks/useGetPartnerList'
export type Pros = {
   errors: any
   control: any
   loyaltyPlanId?: string
   isTouched?: boolean
}
const MemberField = (props: Pros) => {
   const { errors, control, loyaltyPlanId, isTouched } = props
   const intl = useIntl()
   const { data: partnerList, status } = useGetPartnerList({
      ...(loyaltyPlanId && { loyaltyPlanIds: [parseInt(loyaltyPlanId)] }),
   } as GetPartnerListRequest)
   return (
      <Controller
         name={`memberId`}
         control={control}
         rules={{ required: intl.formatMessage({ id: 'operation.errors.memberId' }) }}
         render={({ field: { value, onChange } }) => (
            <Select
               disabled={!loyaltyPlanId || status === 'pending'}
               label={intl.formatMessage({ id: 'operation.form.memberId' })}
               value={value}
               onChange={onChange}
               error={errors?.memberId?.message}
               showErrorText={true}
               className="mt-2"
               isTouched={isTouched}
            >
               <Select.Option value="">--</Select.Option>
               {partnerList &&
                  partnerList.items.map((program) => (
                     <Select.Option key={program.id} value={program.id}>
                        {program.firstName}
                     </Select.Option>
                  ))}
            </Select>
         )}
      />
   )
}

export default MemberField
