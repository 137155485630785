import qs from 'qs'
import { Operation } from '../components/operations'
import { Partner } from '../components/partners'

export type OperationRow = {
   id: number
   reservationName: string
   reservationCode: string
   date: string
   partner: Partner
   accumulatedPoints: number
   redeemedPoints: number
}

export function toTableItem(operation: Operation): OperationRow {
   return {
      id: operation.id,
      reservationName: operation.reservationName,
      reservationCode: operation.reservationCode,
      date: operation.reservationDate,
      partner: operation.member,
      accumulatedPoints: operation.pointsAccumulated || 0,
      redeemedPoints: operation.pointsRedeemed || 0,
   }
}

type Request = {
   query: string
   page: number
   size: number
   sort: string[]
   loyaltyPlanIds: number[]
   from?: string
   to?: string
}

export function getCurrentValues(search: string): Request {
   const result = qs.parse(search, {
      ignoreQueryPrefix: true,
      allowDots: true,
   }) as {
      query?: string
      page?: string
      size?: string
      sort?: string
      loyaltyPlanIds?: string
      dates?: any
   }
   return {
      query: result.query || '',
      page: parseInt(result.page || '0'),
      size: parseInt(result.size || '10'),
      loyaltyPlanIds: result.loyaltyPlanIds ? result?.loyaltyPlanIds.split(',').map(Number) : [],
      ...(result.dates?.dateType === 'other' && {
         from: result.dates?.startDate,
         to: result.dates?.endDate,
      }),
   } as Request
}
